export default class Youtube {
  constructor(element) {
    this.el = element;
    this.player = null;
    this.url = this.el.getAttribute('data-url');
    this.open = false;
    this.canPlay = false;
    this.id = 'youtubePlayer';
    this.init();
  }

  init() {

    const newId = this.id+(Math.round(Math.random()*10000));

    const idEl = this.el.querySelector('#'+this.id);
    if (!idEl) return;

    idEl.setAttribute('id',newId);
    this.id = newId;
    // setup some youtube stuff (https://developers.google.com/youtube/iframe_api_reference)
    const tag = document.createElement('script');

    // detect multiple players
    if (document.querySelectorAll('.youtube').length > 1 && !window.multiYoutube) {
      //prevent multiple runs
      window.multiYoutube = true;
    }

    if (!window.youTubeLoaded) {
      window.youTubeLoaded = true;
      tag.src = 'https://www.youtube.com/player_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    window.onYouTubeIframeAPIReady = () => {
      const event = new Event('youtubeApiReady');
      window.dispatchEvent(event);
    };
    this.el.querySelector('.youtube__frame').addEventListener('click', () => this.play());
  }

  play() {
    document.querySelectorAll('iframe[sandbox]').forEach(s => {
      const ytid = s.id;
      const html = `
      <svg viewBox="-50 -50 300 300" class="youtube__frame-play-triangle" preserveAspectRatio="none">
          <polygon stroke-linejoin="round" points="100,0 0,200 200,200" />
      </svg>
      <div class="w-full h-full" id="${ytid}" style="display:none"></div>
      `;
      const parent = s.parentElement;
      s.remove();
      parent.innerHTML += html;
      document.querySelectorAll('.youtube__frame').forEach(e => e.style.cursor = 'pointer');
    });
    const ytId = this.id;

    // eslint-disable-next-line no-undef
    this.player = new YT.Player(this.id, {
      videoId: this.url,
      playerVars: {
        'playsinline': 1,
        'modestbranding': 1,
        'enablejsapi': 1,
      },
      events: {
        'onReady': () => {
          this.open = true;
          this.el.querySelector('.youtube__frame-play-triangle').remove();
          this.el.querySelector('.youtube__frame').style.cursor = 'default';
          this.el.querySelector('#'+ytId).style.display = 'block';
          this.player.playVideo();
        },
      },
    });

  }
}
