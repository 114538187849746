import axios from 'axios';
import paginationBuilder from '../../util/paginationBuilder';
import { getParamsObj, updateBrowserState } from '../../util/browserUtil';

export default class EventsList {
  constructor(element) {
    this.el = element;
    this.organizers = [];
    this.cities = [];
    this.page = 1;
    this.organizerPicker = null;
    this.cityPicker = null;
    this.params = null;
    this.tags = '';
    this.page = 1;
    this.numberOfPages = 100;
    this.data = {};
    this.eventsItemHtml = null;
    this.eventsItems = null;
    this.dates = [];
    this.init();
  }
  // manage changes to this.data (change filter states, events etc.)
  set _data(value) {
    this.data = value;
    if (value?.TagCategoryList) {
      const organizerTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'Arrangoerer');
      const cityTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'Byer');
      const dateTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'dates');
      this.datePicker.Update(dateTags, this.dates, this.datesChangeHandler);
      this.organizerPicker.Update(organizerTags, this.organizers, this.organizersChangeHandler);
      this.cityPicker.Update(cityTags, this.cities, this.citiesChangeHandler);

    }
    this.numberOfPages = value.SearchResult.Paging.PagesToShow.length;
    this.loadevents(value.SearchResult.Items);
    this.managePagination();
  }
  init() {
    // Element getters
    this.organizerPicker = this.el.querySelector('#filterOrganizer');
    this.cityPicker = this.el.querySelector('#filterCity');
    this.datePicker = this.el.querySelector("#filterDates");
    // load reference html and remove from dom
    this.eventsItemHtml = this.el.querySelector('#eventsItemPlaceholder');
    this.eventsItemHtml.parentElement.removeChild(this.eventsItemHtml);
    this.eventsItemHtml.removeAttribute('id');

    this.eventsItems = this.el.querySelector('.eventsItems');
    this.manageState();

    this.datesChangeHandler = (selectedDates) => {
      this.dates = selectedDates;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    }
    this.organizersChangeHandler = (selectedOrganizers) => {
      this.organizers = selectedOrganizers;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    }
    this.citiesChangeHandler = (selectedCities) => {
      this.cities = selectedCities;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    }

    var currentComponent = this;
    window.addEventListener('resize', function(event){
      currentComponent.onViewportResize(event);
    }, true);    
  }

  loadevents(items) {
    this.eventsItems.innerHTML = '';
    items.forEach(item => {
      const eventsItem = this.eventsItemHtml.cloneNode(true);
      console.log(item);
      eventsItem.querySelector('.eventsItem__headline').innerText = item.Headline;
      eventsItem.querySelector('.eventsItem__text').innerText = item.Description;
      eventsItem.querySelector('.eventsItem__url').href = item.Url;
      eventsItem.querySelector('.eventsItem_date').innerText = item.EventDateFormatted;
      eventsItem.setAttribute('data-id', item.Id);
      
      if (item.TagCategoryList.Categories?.length) {
        const subjectTags = item.TagCategoryList.Categories.find(x => x.Name === 'Emner');
        if (subjectTags) {
          const eventsSubjects = eventsItem.querySelector('.eventsItem__subject');
          eventsSubjects.innerText = subjectTags.Tags.map(s => s.Headline).join(', ');
        }
        const organizerTags = item.TagCategoryList.Categories.find(x => x.Name === 'Arrangoerer');
        if (organizerTags) {
          const eventsOrganizer = eventsItem.querySelector('.eventsItem__organizer');
          eventsOrganizer.innerText = organizerTags.Tags.map(s => s.Headline).join(', ');
        }
        const cityTags = item.TagCategoryList.Categories.find(x => x.Name === 'Byer');
        if (cityTags) {
          const eventCities = eventsItem.querySelector('.eventsItem__city');
          eventCities.innerText = cityTags.Tags.map(s => s.Headline).join(', ');
        }

      }

      

      if (item.ImageUrls?.Items.length) {
        const img = item.ImageUrls.Items.find(x => x.Alias == 'List');
        if (img) {
          eventsItem.querySelector('.eventsItem__image').alt = img.Alt;
        }
      }
      this.eventsItems.appendChild(eventsItem);
      this.setImageCrop(item, eventsItem);
    });
  }

  onViewportResize(event){
    if(this.data?.SearchResult){
      const domItems = [...document.querySelectorAll('.eventsItem')];
      domItems.forEach(domItem => {
        var itemId = domItem.getAttribute('data-id');
        var dataItem = this.data.SearchResult.Items.find(item => item.Id == itemId);
        this.setImageCrop(dataItem, domItem);
      });
    }
    else{
      console.log('no data');
    }
  }

  setImageCrop(dataItem, domItem){
    if(dataItem){
      if(dataItem.ImageUrls?.Items.length){
        // Default
        const img = dataItem.ImageUrls.Items.find(x => x.Alias == 'List');
        domItem.querySelector('.eventsItem__image').src = img.Url;

        // Adjust according to current offset
        if(domItem.offsetWidth < 768){
          const imgMedium = dataItem.ImageUrls.Items.find(x => x.Alias == 'Tile');
          domItem.querySelector('.eventsItem__image').src = imgMedium.Url;        
        }
        else if(domItem.offsetHeight > 150 && domItem.offsetHeight <= 180){
          const imgMedium = dataItem.ImageUrls.Items.find(x => x.Alias == 'ListMedium');
          domItem.querySelector('.eventsItem__image').src = imgMedium.Url;
        }        
        else if(domItem.offsetHeight > 180 && domItem.offsetHeight <= 225){
          const imgMedium = dataItem.ImageUrls.Items.find(x => x.Alias == 'ListLarge');
          domItem.querySelector('.eventsItem__image').src = imgMedium.Url;
        }
        else if(domItem.offsetHeight > 225){
          const imgLarge = dataItem.ImageUrls.Items.find(x => x.Alias == 'Tile');
          domItem.querySelector('.eventsItem__image').src = imgLarge.Url;
        }
      }
      else if(domItem.querySelector('.eventsItem__image')){
          domItem.querySelector('.eventsItem__image').remove();
      }
    }
    else{
      console.log('dataitem is null');
    }
  }

  manageState() {
    this.manageParams();
    let url = `${window.location.origin}/api/event/eventListJson?organizers=${this.organizers.join('|')}&cities=${this.cities.join('|')}&dates=${this.dates.join('|')}&page=${this.page}`;
    axios.get(url).then(response => {
      this._data = response.data;
      if (this.data) {
        const organizerTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'Arrangoerer');
        const cityTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'Byer');
        const dateTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'dates');
        this.datePicker.Update(dateTags, this.dates, this.datesChangeHandler);
        this.organizerPicker.Update(organizerTags, this.organizers, this.organizersChangeHandler);
        this.cityPicker.Update(cityTags, this.cities, this.citiesChangeHandler);
      }
    });
  }

  manageParams() {
    this.params = getParamsObj();
    if (this.params.organizers) {
      this.organizers = this.params.organizers.split('|');
    }
    if (this.params.cities) {
      this.cities = this.params.cities.split('|');
    }
    if (this.params.page) {
      this.page = parseInt(this.params.page);
    }
    if (this.params.dates) {
      this.dates = this.params.dates.split('|');
    }
  }

  managePagination() {
    if (this.numberOfPages == 1) {
      if (this.el.querySelector('.pagination'))
        this.el.querySelector('.pagination').classList.add('hidden');
      return;
    }
    this.el.querySelector('.pagination').classList.remove('hidden');
    const callback = e => {
      const value = e.target.closest('a[data-value]').getAttribute('data-value');
      if (value) {
        if (value == 'prev') this.page--;
        else if (value == 'next') this.page++;
        else this.page = parseInt(value)+1;
        paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
      }
      updateBrowserState(this.nextUrl());
      this.manageState();
      e.preventDefault();
    };

    paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
  }

  nextUrl(resetPage = false) {
    if (resetPage) {
      this.page = 1;
    }
    let nextUrl = `?organizers=${this.organizers.join('|')}&cities=${this.cities.join('|')}&page=${this.page}`;
    if (this.dates) {
      nextUrl += `&dates=${this.dates.join('|')}`;
    }
    return nextUrl;
  }
}
