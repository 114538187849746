import {disableScrollLock, enableScrollLock} from '../../util/bodyScroll';
export default class Menu {
  constructor(element) {
    this.el = element;
    this.init();
  }

  init() {
    const openBtn = this.el.querySelector('button.menu-button');
    const closeBtn = this.el.querySelector('a.menu-close');
    const sideMenu = this.el.querySelector('.side-menu');
    const overlay = document.querySelector('.overlay');
    const fadeContainer = document.querySelector('.fade-container');
    const toggleBtn = document.querySelector('.toggleLang');
    const langContainer = document.querySelector('.langcontainer');
    openBtn.addEventListener('click', () => {
      openMenu();
    });

    closeBtn.addEventListener('click', () => {
      closeMenu();
    });

    function openMenu() {
      langContainer.classList.add('notransition');
      langContainer.classList.remove('active');
      langContainer.offsetHeight;
      langContainer.classList.remove('notransition');
      sideMenu.classList.remove('inactive');
      sideMenu.classList.add('active');
      overlay.classList.remove('hidden');
      fadeContainer.classList.add('active');
      fadeContainer.classList.remove('inactive');
      enableScrollLock(true);
    }
    function closeMenu() {
      sideMenu.classList.remove('active');
      sideMenu.classList.add('inactive');
      fadeContainer.classList.remove('active');
      fadeContainer.classList.add('inactive');
      overlay.classList.add('hidden');
      disableScrollLock(true);
    }
  }
}
