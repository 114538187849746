import {enableScrollLock, disableScrollLock} from '../../util/bodyScroll';
export default class LanguagePicker {
  constructor(element) {
    this.el = element;
    this.init();
  }

  init() {
    console.log(this.el);
    const toggleBtn = document.querySelector('.toggleLang');
    const langContainer = document.querySelector('.langcontainer');
    toggleBtn.addEventListener('click', () => {
      if (langContainer.classList.contains("active")) {
        disableScrollLock(true);
        langContainer.classList.remove('active');
      } else {
        enableScrollLock(true);
        langContainer.classList.add('active');
      }
  });
  }
}
