export function disableScrollLock() {
  const innerBody = document.querySelector(".innerBody");
  if (document.readyState === 'complete') {
    innerBody.style.position = '';
    innerBody.style.overflowY = '';
    innerBody.style.width = '';
    innerBody.classList.add('h-full');
    innerBody.classList.add('min-h-full');
    document.body.classList.add('h-full');
    if (innerBody.style.marginTop) {
      const scrollTop = -parseInt(innerBody.style.marginTop, 10);
      innerBody.style.marginTop = '';
      window.scrollTo(window.pageXOffset, scrollTop);
    }
  } else {
    window.addEventListener('load', enableBodyScroll);
  }
}

export function enableScrollLock({ savePosition = false } = {}) {
  const innerBody = document.querySelector(".innerBody");
  if (document.readyState === 'complete') {
    if (innerBody.scrollHeight > window.innerHeight) {
      if (savePosition) innerBody.style.marginTop = `-${window.pageYOffset}px`;
      innerBody.style.position = 'fixed';
      innerBody.style.overflowY = 'scroll';
      innerBody.style.width = '100%';
      innerBody.classList.remove('h-full');
      innerBody.classList.remove('min-h-full');
      document.body.classList.remove('h-full');
    }
  } else {
    window.addEventListener('load', () => disableBodyScroll({ savePosition }));
  }
}