let pagination_a = null;
let pagination_span = null;

export default function paginationBuilder(element, currentPage, numberOfPages, callback) {
  const leftArrow = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="flex w-3.5 h-3.5"><path d="M22.548,10.561H5.437a.251.251,0,0,1-.165-.438l8.637-7.6a1.44,1.44,0,0,0-1.9-2.162L.828,10.2a2.4,2.4,0,0,0,0,3.6l11.179,9.837a1.44,1.44,0,0,0,1.9-2.161l-8.637-7.6a.251.251,0,0,1,.165-.438H22.548a1.44,1.44,0,0,0,0-2.879Z" fill="currentColor"></path></svg>`;
  const rightArrow = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="flex w-3.5 h-3.5"><path d="M23.987,12a2.411,2.411,0,0,0-.814-1.8L11.994.361a1.44,1.44,0,0,0-1.9,2.162l8.637,7.6a.25.25,0,0,1-.165.437H1.452a1.44,1.44,0,0,0,0,2.88H18.563a.251.251,0,0,1,.165.438l-8.637,7.6a1.44,1.44,0,1,0,1.9,2.161L23.172,13.8A2.409,2.409,0,0,0,23.987,12Z" fill="currentColor"></path></svg>`; 
  const dots = '…'; 
  const pageDotRatio = 3;
  //get reference html
  if (!pagination_a) pagination_a = element.querySelector('a').cloneNode(true);
  if (!pagination_span) pagination_span = element.querySelector('span').cloneNode(true);

  const a = pagination_a;
  const span = pagination_span; 

  //clear html for building 
  element.innerHTML = '';
  //chop the pages into segments of [0,1,2][3,4,5] etc.
  const segments = [];
  let segmentPosition = -1;

  let whilePageCount = numberOfPages;
  //we work our way backwards
  while (whilePageCount > 0) {
    const segment = [];
    for (let i = 0; i < pageDotRatio; i++) {
      if (numberOfPages-whilePageCount >= 0 && whilePageCount > 0) {
        segment.push(numberOfPages-whilePageCount);
        whilePageCount--;

        if (numberOfPages-whilePageCount == currentPage)
          segmentPosition = segments.length;
      }
    }
    segments.push(segment);
  }
  // build <li> list items for insertion
  const listItems = [];

  if (segmentPosition > 0) {
    listItems.push(makeLiNode(a,'prev',leftArrow, false));
  }
  if (currentPage > pageDotRatio) {
    listItems.push(makeLiNode(a,0,1, false));
    listItems.push(makeLiNode(span,'',dots));
  }

  segments[segmentPosition].forEach(s => {
    listItems.push(makeLiNode(a,s,s+1, s+1 == currentPage));
  });

  if (numberOfPages > pageDotRatio && segmentPosition < segments.length-1) {
    listItems.push(makeLiNode(span,'',dots));
    listItems.push(makeLiNode(a,numberOfPages-1,numberOfPages, false));
    listItems.push(makeLiNode(a,'next',rightArrow, false));
  }

  listItems.forEach(li => {
    element.appendChild(li);
    // add our callback to all the new items
    li.addEventListener('click',callback);
  });
}

function makeLiNode(node, data, text, active) {
  const n = node.cloneNode(true);
  n.setAttribute('data-value',data);
  n.innerHTML = text;
  const li = document.createElement('li');
  if (active) {
    n.classList.add('text-white');
    n.classList.add('text-blue-dark'); 
  }
  li.appendChild(n);
  return li;
}
