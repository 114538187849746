export default class FWSlider {
  constructor(element) {
    this.el = element;
    this.touchstartX = 0;
    this.touchendX = 0;
    this.slides = [...this.el.querySelectorAll('.fwSlider__slide')];
    this.slideWidth = 0;
    this.activeSlides = [];
    this.transition = 'all ease 0.4s';
    this.direction = 'left';
    this.parent = this.el.parentNode;
    this.buttons = this.parent.querySelector('.fwSlider__buttons');
    this.transitioning = false;
    this.slideSpeed = 400;
    this.init();
  }

  init() {
    if (this.slides.length < 3) {
      this.abortInitAndMakeToImage();
      return;
    }

    this.initSlides();
    this.buildSlider();
    this.centerSlide();

    // less invasive than window.addEventListener('resize')
    new ResizeObserver(() => {
      this.slideWidth = this.el.querySelector('.fwSlider__slide').getBoundingClientRect().width;
      this.el.style.left = -18+((this.clientWidth()-this.slideWidth)/2)+(-this.slideWidth*2) +'px';

      this.setButtons(this.el.querySelectorAll('.fwSlider__slide')[2]);
    }).observe(this.el);

    // event handlers

    // reinit slider when a dot is clicked, at correct position
    this.parent.querySelectorAll('.fwSlider__buttons__dots a').forEach(a => a.addEventListener('click', e => {
      const number = parseInt(e.target.getAttribute('data-number'));

      this.initSlidesFromNumber(number);
      this.buildSlider();
      this.centerSlide();
    }));

    // deal with buttons
    this.parent.querySelector('.fwSlider__buttons-next').addEventListener('click', () => this.next());
    this.parent.querySelector('.fwSlider__buttons-prev').addEventListener('click', () => this.prev());
    // deal with phone slide
    this.el.addEventListener('touchstart', e => {
      this.touchstartX = e.changedTouches[0].screenX;
    });
    this.el.addEventListener('touchend', e => {
      this.touchendX = e.changedTouches[0].screenX;
      if (this.touchendX < this.touchstartX)
        this.next();
      if (this.touchendX > this.touchstartX)
        this.prev();
    });
  }

  abortInitAndMakeToImage() {
    // hide buttons, set fallback class on first slide and remove rest.
    this.buttons.style.display = 'none';
	if(this.slides.length > 0){
		this.slides[0].classList.add('fallback');
	}    
    this.el.classList.add('fallback');
    this.slides.forEach(f => {
      if (!f.classList.contains('fallback')) f.remove();
    });
  }

  initSlidesFromNumber(number) {
    if (number < 0) number = 0;

    this.activeSlides = [];
    this.activeSlides.push(this.slides[number-1 >= 0 ? number-1 : this.slides.length-1].cloneNode(true));
    this.activeSlides.push(this.slides[number-1 >= 0 ? number-1 : this.slides.length-1].cloneNode(true));
    this.activeSlides.push(this.slides[number].cloneNode(true));
    this.activeSlides.push(this.slides[number < this.slides.length-1 ? number +1 : 0].cloneNode(true));
    this.activeSlides.push(this.slides[number < this.slides.length-1 ? number +1 : 0].cloneNode(true));
  }

  initSlides() {
    for (let i = 0; i < this.slides.length; i++) {
      this.slides[i].setAttribute('data-number', i);
    }
    this.activeSlides.push(this.slides[this.slides.length-1].cloneNode(true));
    this.activeSlides.push(this.slides[this.slides.length-1].cloneNode(true));
    this.activeSlides.push(this.slides[0].cloneNode(true));
    this.activeSlides.push(this.slides[1].cloneNode(true));
    this.activeSlides.push(this.slides[1].cloneNode(true));
  }

  buildSlider() {
    const tmp = document.createElement('div');
    this.activeSlides.forEach(slide => tmp.append(slide));

    this.el.innerHTML = tmp.innerHTML;
    const rect = this.el.querySelectorAll('.fwSlider__slide')[2].getBoundingClientRect();
    this.slideWidth = rect.width;
  }

  resetSlideState(direction) {
    if (direction == 'left') {
      this.activeSlides.shift();
      this.el.querySelectorAll('.fwSlider__slide')[0].remove();
      this.el.style.left = -18+((this.clientWidth()-this.slideWidth)/2)+(-this.slideWidth*2) +'px';
      this.el.append(this.el.querySelectorAll('.fwSlider__slide')[3].cloneNode(true));
    }
    else if (direction == 'right') {
      this.activeSlides.pop();
      this.el.querySelectorAll('.fwSlider__slide')[4].remove();
      this.el.style.left = ((this.clientWidth()-this.slideWidth)/2)+(-this.slideWidth*2) +'px';
      this.el.prepend(this.el.querySelectorAll('.fwSlider__slide')[0].cloneNode(true));
    }
    this.setActiveButton(this.el.querySelector('.fwSlider__slide.focus').getAttribute('data-number'));
    this.transitioning = false;
  }

  clientWidth() {
    return window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
  }

  next() {
    if (this.transitioning) return;
    // get real slide index
    const slideNumber = parseInt(this.el.querySelectorAll('.fwSlider__slide')[2].getAttribute('data-number'));
    const isLast = slideNumber == this.slides.length-1;
    const isSecondLast = slideNumber == this.slides.length-2;
    const nextSlide = !isLast ? slideNumber + 1 : 0;
    const rightSlide = isLast ? 1 : isSecondLast ? 0 : nextSlide+1;

    this.activeSlides[4] = this.slides[rightSlide].cloneNode(true);

    this.el.querySelectorAll('.fwSlider__slide')[4].innerHTML = this.activeSlides[4].innerHTML;
    this.el.querySelectorAll('.fwSlider__slide')[4].setAttribute('data-number', this.activeSlides[4].getAttribute('data-number'));
    this.slideLeft();
  }

  slideLeft() {
    this.el.style.transition = this.transition;
    this.transitioning = true;
    this.el.querySelectorAll('.fwSlider__slide').forEach(s => s.classList.remove('focus'));

    const slide = this.el.querySelectorAll('.fwSlider__slide')[3];

    slide.classList.add('focus');

    // math -_- (window width minus half of slider (to insert to center) and then offset with number of slides)
    this.el.style.left = -18+((this.clientWidth()-this.slideWidth)/2)+(-this.slideWidth*3) +'px';

    setTimeout(() => {
      this.el.style.transition = '';
      this.resetSlideState('left');
    }, this.slideSpeed);
  }

  prev() {
    if (this.transitioning) return;
    // get real slide index
    const slideNumber = parseInt(this.el.querySelectorAll('.fwSlider__slide')[2].getAttribute('data-number'));
    const isFirst = slideNumber == 0;
    const isLast = slideNumber == this.slides.length-1;

    let leftSlide = isFirst ? this.slides.length-2 : isLast ? this.slides.length-3 : slideNumber - 2;
    if (leftSlide < 0) leftSlide = this.slides.length-1;
    this.activeSlides[0] = this.slides[leftSlide].cloneNode(true);

    this.el.querySelectorAll('.fwSlider__slide')[0].innerHTML = this.activeSlides[0].innerHTML;
    this.el.querySelectorAll('.fwSlider__slide')[0].setAttribute('data-number', this.activeSlides[0].getAttribute('data-number'));
    this.slideRight();
  }

  slideRight() {
    this.el.style.transition = this.transition;
    this.transitioning = true;
    this.el.querySelectorAll('.fwSlider__slide').forEach(s => s.classList.remove('focus'));

    const slide = this.el.querySelectorAll('.fwSlider__slide')[1];

    slide.classList.add('focus');

    // math -_- (window width minus half of slider (to insert to center) and then offset with number of slides)
    this.el.style.left = -18+((this.clientWidth()-this.slideWidth)/2)+(-this.slideWidth) +'px';

    setTimeout(() => {
      this.el.style.transition = '';
      this.resetSlideState('right');
    }, this.slideSpeed);
  }

  centerSlide() {
    this.el.querySelectorAll('.fwSlider__slide').forEach(s => s.classList.remove('focus'));
    const slide = this.el.querySelectorAll('.fwSlider__slide')[2];
    slide.classList.add('focus');

    this.setButtons(slide);
    this.setActiveButton(slide.getAttribute('data-number'));
  }

  setButtons(slide) {
    const rect = slide.getBoundingClientRect();
    let buttonWidth = this.buttons.getBoundingClientRect().width;
    this.buttons.style.top = rect.height-51 + 'px';

    this.buttons.style.left = -18+((this.clientWidth()-rect.width)/2)+rect.width-(buttonWidth-13) + 'px';
    // fix for when JS gets it wrong and miscalculates button-width
    setTimeout(() => {
      buttonWidth = this.buttons.getBoundingClientRect().width;
      this.buttons.style.left = -16+((this.clientWidth()-rect.width)/2)+rect.width-(buttonWidth-13) + 'px';
    }, 1);

  }
  setActiveButton(number) {
    this.parent.querySelectorAll('.fwSlider__buttons__dots a').forEach(a => {
      if (a.getAttribute('data-number') == number) a.classList.add('focus');
      else a.classList.remove('focus');
    });
  }
}
