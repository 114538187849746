import axios from "axios";
import Youtube from "./../youtube/youtube";
import { getParamsObj, updateBrowserState } from "../../util/browserUtil";

export default class NewyearSpeeches {
  constructor(element) {
    this.el = element;
    this.itemHTML = this.el
      .querySelector(".newyear__content__item")
      .cloneNode(true);
    this.contextId = this.el.getAttribute("data-contextId");
    this.personPicker = this.el.querySelector("#newYearsPerson");
    this.yearPicker = this.el.querySelector("#newYearsYear");
    this.data = null;
    this.tags = [];
    this.years = [];
    this.downloadText = this.el.getAttribute("data-downloadText");
    this.filterTags = [];
    this.filterYears = [];
    this.init();
  }

  init() {
    this.el.querySelector(".newyear__content__item").remove();

    this.manageState();

    this.personPicker.addEventListener("change", (event) => {
      this.filterTags = Array.from(event.target.selectedOptions)
        .filter((x) => x.value)
        .map((x) => x.value)
        .filter((x) => x !== "");
      updateBrowserState(this.nextUrl());
      this.filter();
    });

    this.yearPicker.addEventListener("change", (event) => {
      this.filterYears = Array.from(event.target.selectedOptions)
        .filter((x) => x.value)
        .map((x) => x.value)
        .filter((x) => x !== "");
      updateBrowserState(this.nextUrl());
      this.filter();
    });
  }

  manageState() {
    const url = `${window.location.origin}/api/NewYearComponent/NewYearSpeechesJson`;

    axios
      .post(url, {
        ContextId: this.contextId,
      })
      .then((response) => {
        this.data = response.data;
        this.buildContent();

        this.manageParams();
        this.filter();
      });
  }

  filter() {
    const ul = this.el.querySelectorAll(".newyear__content li");
    const filters = this.filterTags;
    const yearFilters = this.filterYears;

    if (yearFilters.length || filters.length)
      ul.forEach((li) => (li.style.display = "none"));

    if (yearFilters.length && !filters.length) {
      ul.forEach((li) => {
        const year = li.getAttribute("data-year");
        if (yearFilters.includes(year)) li.style.display = "block";
      });
    } else if (filters.length && !yearFilters.length) {
      ul.forEach((li) => {
        const id = li.getAttribute("data-id");
        if (filters.includes(id.toLowerCase())) li.style.display = "block";
      });
    } else if (filters.length && yearFilters.length) {
      ul.forEach((li) => {
        const id = li.getAttribute("data-id");
        const year = li.getAttribute("data-year");
        if (filters.includes(id.toLowerCase()) && yearFilters.includes(year))
          li.style.display = "block";
      });
    } else {
      ul.forEach((li) => (li.style.display = "block"));
    }
  }

  manageParams() {
    this.filterTags = getParamsObj()
      ["tags"]?.split("|")
      .filter((x) => x !== "");
    this.filterYears = getParamsObj()
      ["years"]?.split("|")
      .filter((x) => x !== "");

    if (!this.filterTags) this.filterTags = [];
    if (!this.filterYears) this.filterYears = [];

    this.SetSelectOptions(this.yearPicker, this.years, this.filterYears, true);
    this.SetSelectOptions(this.personPicker, this.tags, this.filterTags, false);
  }
  nextUrl() {
    return `?tags=${this.filterTags.join("|")}&years=${this.filterYears.join(
      "|"
    )}`;
  }

  buildContent() {
    const ul = this.el.querySelector(".newyear__content");

    this.data.forEach((video) => {
      console.log(video);
      const li = this.itemHTML.cloneNode(true);
      if (!video.PreviewImageSrc) {
        video.PreviewImageSrc = `https://img.youtube.com/vi/${video.YoutubeLink}/maxresdefault.jpg`;
      }
      li.classList.remove("hidden");
      li.setAttribute(
        "data-id",
        video.tags.Items.filter((x) => x.RootAlias === "PersonPage")[0]?.Id
      );
      li.setAttribute("data-year", video.Year);
      li.querySelector(
        ".newyear__content__item-preview"
      ).style.background = `url(${video.PreviewImageSrc})`;
      li.querySelector(".newyear__content__item-preview").style.backgroundSize =
        "cover";
      li.querySelector(".newyear__content__item-title").innerText =
        video.tags.Items.filter(
          (f) => f.RootAlias === "PersonPage"
        )[0]?.Headline;

      li.querySelector(".newyear__content__item-heading").innerText =
        video.tags.Items.filter((f) => f.RootAlias === "PersonPage")[0]?.Name +
        " | " +
        video.Year;

      li.querySelector(".newyear__content__item-description").innerText = video.Description;
      if (video.FileSrc !== null) {
        console.log(video.FileSrc);
        li.querySelector(".newyear__content__item-link").href = video.FileSrc;
        li.querySelector(".newyear__content__item-size").innerText =
          video.FileSize;
        li.querySelector(".newyear__content__item-dltext").innerText =
          this.downloadText;
      } else {
        li.removeChild(li.querySelector(".newyear__content__file"));
      }
      if (video.YoutubeLink) {
        li.setAttribute("data-url", video.YoutubeLink);
        new Youtube(li);
      } else {
        li.querySelector("#playBtn").classList.add("hidden");
        li.querySelector(".youtube__frame").style.cursor = "initial";
      }

      ul.append(li);

      if (!this.years[video.Year]) {
        this.years.push(video.Year);
      }

      if (video.tags) {
        var personTag = video.tags.Items.filter(
          (f) => f.RootAlias === "PersonPage"
        )[0];
        if (personTag && !this.tags.some((tag) => tag.Id === personTag.Id)) {
          this.tags.push(personTag);
        }
      }
    });
    this.tags = this.tags.filter((v, i, a) => a.indexOf(v) === i);

    this.years = this.years.filter((v, i, a) => a.indexOf(v) === i);

    this.SetSelectOptions(this.personPicker, this.tags);
    this.SetSelectOptions(this.yearPicker, this.years, null, true);
  }

  SetSelectOptions(element, values, value, isYear) {
    //popuplate fields if it's empty or only have a non-value option
    const options = element.querySelectorAll('option:not([value=""])');
    if (options.length == 0) {
      values.forEach((v) => {
        const o = document.createElement("option");
        if (!isYear) {
          o.innerText = v.Name;
          o.value = v.Id;
        } else {
          o.innerText = v;
          o.value = v;
        }

        element.appendChild(o);
      });
    }
    //set selected from state
    if (value) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          element.querySelector(`option[value="${v}"]`).selected = true;
        });
      } else element.querySelector(`option[value="${value}"]`).selected = true;
    }
  }
}
