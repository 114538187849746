export default class Dropdown {
  constructor(element) {
    this.el = element;
    this.select = null;
    this.list = null;
    this.optionHtml = null;
    this.label = null;
    this.labelText = null;
    this.labelArray = [];
    this.init();
  }

  init() {
    // hide actual select
    this.select = this.el.querySelector('select');
    this.list = this.el.querySelector('.dropdown-list');
    this.select.classList.add('hidden');
    this.label = this.el.querySelector('.dropdown-label');
    this.labelText = this.label.innerText;
    this.optionHtml = this.list.querySelector('li');
    this.optionHtml.parentElement.removeChild(this.optionHtml);
    this.isMultiple = this.select.multiple;
    // initialise the dropdown if it's filled through dom manipulation
    const observer = new MutationObserver( () => {
      this.observe();
    });
    observer.observe(this.select, { attributes: true, childList: true, subtree: true });

    // initialise the dropdown if it's filled serverside
    if (this.el.getAttribute('data-dropdown') && this.el.getAttribute('data-dropdown') === 'server-side') {
      const val = this.el.getAttribute('data-value');
      if (val && val != '') {
        const option = this.select.querySelector('option[value="' + val + '"]');
        option.selected = true;
      }
      this.observe();
    }

    this.el.addEventListener('click', () => {
      const container = this.el.querySelector('.dropdown-container');

      container.classList.toggle('hidden');

      if (!container.classList.contains('hidden')) {
        this.el.querySelector('.dropdown-chevron').style.transform = 'rotate(180deg)';
        container.style.height = `${container.scrollHeight}px`;

        setTimeout(() => {
          document.addEventListener('click', e => {
            if (!e.target.closest('.dropdown')) {
              document.querySelectorAll('.dropdown-container').forEach(d => d.classList.add('hidden'));
              document.querySelectorAll('.dropdown-chevron').forEach(d => d.style.transform = 'rotate(0deg)');
            }
          }, {once: true});
        },50);
      }
      else {
        this.el.querySelector('.dropdown-chevron').style.transform = 'rotate(0deg)';
        container.style.height = 0;
      }
    });
  }

  observe() {
    this.manageState();
    this.select = this.el.querySelector('select');
    this.select.addEventListener('change', () => this.manageState());
  }

  manageState() {
    this.list.innerHTML = '';
    this.labelArray = [];
    this.select.querySelectorAll('option:not([value=""])').forEach(element => {

      const listItem = this.optionHtml.cloneNode(true);

      listItem.querySelector('span').innerText = element.innerText;

      this.list.appendChild(listItem);

      listItem.setAttribute('data-value', element.value);

      const checkbox = listItem.querySelector('input[type="checkbox"]');

      checkbox.checked = element.selected;
      if (checkbox.checked) this.labelArray.push(element.innerText);
      listItem.addEventListener('click',e => {
        const value = e.target.closest('li').getAttribute('data-value');

        if (value) {
          if (!this.isMultiple) {
            Array.from(this.select.querySelector('option')).forEach(o => o.selected = false);
          }
          const option = this.select.querySelector(`option[value="${value}"]`);
          option.selected = !option.selected;
        }
        this.select.dispatchEvent(new Event('change'));
        e.stopPropagation();
        e.preventDefault();
      });
    });

    if (this.labelArray.length) this.label.innerText = this.labelArray.join(', ');
    else this.label.innerText = this.labelText;
  }
}
