import axios from 'axios';
import paginationBuilder from '../../util/paginationBuilder';
import { format } from 'date-fns';
import { getParamsObj, updateBrowserState, setSelectOptions } from '../../util/browserUtil';

export default class CalendarArchive {
  constructor(element) {
    this.el = element;
    this.organizerTags = [];
    this.page = 1;
    this.organizerPicker = null;
    this.params = null;
    this.tags = '';
    this.page = 1;
    this.numberOfPages = 1;
    this.pageSize = 50;
    this.data = {};
    this.calendarItemHtml = null;
    this.calendarItems = null;
    this.contextitem = '';
    this.subjects = [];
    this.subjectPicker = null;
    this.dates = [];
    this.datePicker = null;
    this.init();
  }
  // manage changes to this.data (change filter states, calendar etc.)
  set _data(value) {
    this.data = value;
    if (value?.Tags) 
    {
      const organizerTags = value.Tags.Nodes.filter(x => x.RootAlias === 'Arrangoerer');
      const dateTags = value.Tags.Nodes.filter(x => x.RootAlias === 'dates');
      const subjectTags = value.Tags.Nodes.filter(x => x.RootAlias === 'Emner');
      this.subjectPicker.Update(subjectTags, this.subjects, this.subjectsChangedHandler);
      this.organizerPicker.Update(organizerTags, this.organizerTags, this.organizerChangedHandler);
      this.datePicker.Update(dateTags, this.dates, this.datesChangedHandler);
    }
    this.numberOfPages = this.data.SearchResult.Paging.PagesToShow.length;
    this.loadcalendar(value.SearchResult.Items);
    this.managePagination();
  }
  init() {
    // Element getters
    this.organizerPicker = this.el.querySelector('#filterOrganizer');
    this.subjectPicker = this.el.querySelector('#filterSubject');
    this.datePicker = this.el.querySelector('#filterDates');
    this.contextitem = this.el.getAttribute('data-contextitem');

    // load reference html and remove from dom
    this.calendarItemHtml = this.el.querySelector('#calendarItemPlaceholder');
    this.calendarItemHtml.parentElement.removeChild(this.calendarItemHtml);
    this.calendarItemHtml.removeAttribute('id');

    this.calendarItems = this.el.querySelector('.calendarItems');
    this.manageState();

    // Event listeners



    var currentComponent = this;
    window.addEventListener('resize', function(event){
      currentComponent.onViewportResize(event);
    }, true);
    
    this.organizerChangedHandler = (selectedOrganizers)=> {
      this.organizerTags = selectedOrganizers;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    };
    this.datesChangedHandler = (selectedDates) => {
      this.dates = selectedDates;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    }
    this.subjectsChangedHandler = (selectedSubjects)=> {
      this.subjects = selectedSubjects;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    };    
  }
  nextUrl(resetPage = false) {
    if (resetPage) {
      this.page = 1;
    }
    let nextUrl = `?organizers=${this.organizerTags.join('|')}&subjects=${this.subjects.join('|')}&dates=${this.dates.join('|')}&page=${this.page}&contextitem=${this.contextitem}&psize=${this.pageSize}`;

    return nextUrl;
  }
  loadcalendar(items) {
    this.calendarItems.innerHTML = '';
    items.forEach(item => {
      const calendarItem = this.calendarItemHtml.cloneNode(true);
      calendarItem.querySelector('.calendarItem__headline').innerText = item.Headline;
      calendarItem.querySelector('.calendarItem__text').innerText = item.Description;
      calendarItem.setAttribute('data-id', item.Id);
      calendarItem.querySelector('.calendarItem__url').href = item.Url;
      if (item.TagCategoryList.Categories?.length) {
        const subjectTags = item.TagCategoryList.Categories.find(x => x.Name === 'Emner');
        if (subjectTags) {
          const calendarSubjects = calendarItem.querySelector('.calendarItem__subject');
          calendarSubjects.innerText = subjectTags.Tags.map(s => s.Headline).join(', ');
        }
        const organizerTags = item.TagCategoryList.Categories.find(x => x.Name === 'Arrangoerer');
        if (organizerTags) {
          const calendarOrganizer = calendarItem.querySelector('.calendarItem__organizer');
          calendarOrganizer.innerText = organizerTags.Tags.map(s => s.Headline).join(', ');
        }
      }

      calendarItem.querySelector('.calendarItem__place').innerText = item.Place;
      calendarItem.querySelector('.calendarItem__date').innerText = item.FormattedDateRange;
      this.calendarItems.appendChild(calendarItem);
      this.setImageCrop(item, calendarItem);
    });
  }

  onViewportResize(event){
    if(this.data?.SearchResult){
      const domItems = [...document.querySelectorAll('.calendarItem')];
      domItems.forEach(domItem => {
        var itemId = domItem.getAttribute('data-id');
        var dataItem = this.data.SearchResult.Items.find(item => item.Id == itemId);
        this.setImageCrop(dataItem, domItem);
      });
    }
    else{
      console.log('no data');
    }
  }

  setImageCrop(dataItem, domItem){
    if(dataItem){
      if(dataItem.ImageUrls?.Items.length){
        // Default
        const img = dataItem.ImageUrls.Items.find(x => x.Alias == 'List');
        domItem.querySelector('.calendarItem__image').src = img.Url;

        // Adjust according to current offset
        if(domItem.offsetWidth < 768){
          const imgMedium = dataItem.ImageUrls.Items.find(x => x.Alias == 'Tile');
          domItem.querySelector('.calendarItem__image').src = imgMedium.Url;        
        }
        else if(domItem.offsetHeight > 150 && domItem.offsetHeight <= 180){
          const imgMedium = dataItem.ImageUrls.Items.find(x => x.Alias == 'ListMedium');
          domItem.querySelector('.calendarItem__image').src = imgMedium.Url;
        }        
        else if(domItem.offsetHeight > 180 && domItem.offsetHeight <= 225){
          const imgMedium = dataItem.ImageUrls.Items.find(x => x.Alias == 'ListLarge');
          domItem.querySelector('.calendarItem__image').src = imgMedium.Url;
        }
        else if(domItem.offsetHeight > 225){
          const imgLarge = dataItem.ImageUrls.Items.find(x => x.Alias == 'Tile');
          domItem.querySelector('.calendarItem__image').src = imgLarge.Url;
        }
      }
      else if(domItem.querySelector('.calendarItem__image')){
          domItem.querySelector('.calendarItem__image').remove();
      }
    }
    else{
      console.log('dataitem is null');
    }
  }


  manageState() {
    this.manageParams();
    let url = `${window.location.origin}/api/CalendarComponent/CalendarArchiveJson?organizers=${this.organizerTags.join('|')}&subjects=${this.subjects.join('|')}&dates=${this.dates.join('|')}&page=${this.page}&contextitem=${this.contextitem}&psize=${this.pageSize}`;

    axios.get(url).then(response => {
      this._data = response.data;
      if (this.data) {
        const organizerTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'Arrangoerer');
        const dateTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'dates');
        const subjectTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'Emner');
        this.subjectPicker.Update(subjectTags, this.subjects, this.subjectsChangedHandler);
        this.organizerPicker.Update(organizerTags, this.organizerTags, this.organizerChangedHandler);
        this.datePicker.Update(dateTags, this.dates, this.datesChangedHandler);
      }
    });
  }

  manageParams() {
    this.params = getParamsObj();
    if (this.params.organizers) {
      this.organizerTags = this.params.organizers.split('|');
    }
    if (this.params.subjects) {
      this.subjects = this.params.subjects.split('|');
    }
    if (this.params.page) {
      this.page = parseInt(this.params.page);
    }
    if (this.params.dates) {
      this.dates = this.params.dates.split('|');
    }
  }

  managePagination() {
    if (this.numberOfPages == 1) {
      if (this.el.querySelector('.pagination'))
        this.el.querySelector('.pagination').classList.add('hidden');
      return;
    }
    this.el.querySelector('.pagination').classList.remove('hidden');
    const callback = e => {
      const value = e.target.closest('a[data-value]').getAttribute('data-value');
      if (value) {
        if (value == 'prev') this.page--;
        else if (value == 'next') this.page++;
        else this.page = parseInt(value)+1;
        paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
      }
      updateBrowserState(this.nextUrl());
      this.manageState();
      e.preventDefault();
    };

    paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
  }
}
