import { getParamsObj, updateBrowserState } from '../../util/browserUtil';
export default class ContactTree {
  constructor(element) {
    this.el = element;
    this.departementPicker = null;
    this.areaPicker = null;
    this.subjects = [];
    this.init();
  }

  init() {
    this.departementPicker = this.el.querySelector('#filterDepartement');
    this.areaPicker = this.el.querySelector('#filterArea');

    this.departementPicker.addEventListener('change', (event) => {
      event.target.querySelectorAll('option').forEach(f => {
        this.subjects = this.subjects.filter(x => x != f.value);
      });
      this.filter(Array.from(event.target.selectedOptions).filter(x => x.value).map(x => x.value), null);
    });

    this.areaPicker.addEventListener('change', (event) => {
      event.target.querySelectorAll('option').forEach(f => {
        this.subjects = this.subjects.filter(x => x != f.value);
      });
      this.filter(null,Array.from(event.target.selectedOptions).filter(x => x.value).map(x => x.value));
    });

    this.el.querySelectorAll('.contact-area-toggler').forEach((item) => {
      item.addEventListener('click', () => {
        item.querySelector('.svg-interaction__chevron').classList.toggle('rotate-180');
        const toggleId = item.getAttribute('data-area-id');
        this.toggleElement(document.querySelector('ul[data-area-id="' + toggleId +'"]'));
      });
    });

    if (getParamsObj()?.tags) {
      this.subjects = Array.from(getParamsObj()?.tags.split('|'));
    }
  }

  toggleElement(element) {
    if (element.style.display === 'none' || element.style.display === '') {
      element.style.display = 'block';
    }
    else {
      element.style.display = 'none';
    }
  }

  filter(department, area) {

    if (department?.length || area?.length) {
      this.el.querySelectorAll('.calendararchive__line').forEach(line => {
        line.classList.add('hidden');

        if (line.querySelector('.contact-area-target')) {
          line.querySelector('.contact-area-target').style.display = 'none';
          line.querySelector('.svg-interaction__chevron').classList.remove('rotate-180');
        }

        if (department?.length && line.getAttribute('data-department') === department[0]) {
          line.classList.remove('hidden');
        }

        if (area?.length && line.getAttribute('data-areas').includes(area[0])) {
          line.classList.remove('hidden');
          line.querySelector('.contact-area-toggler').click();
        }
      });

      if (department) {
        this.subjects = this.subjects.concat(department);
      }
      if (area) {
        this.subjects = this.subjects.concat(area);
      }
    }
    else {
      this.el.querySelectorAll('.calendararchive__line').forEach(line => {
        line.classList.remove('hidden');
        this.subject = [];
        updateBrowserState(`?tags=${this.subjects.join('|')}`);
      });
    }
  }

  SetSelectOptions(element, values, value) {
    //set selected from state
    if (value) {
      if (Array.isArray(value)) {
        value.forEach(v => {
          element.querySelector(`option[value="${v}"]`).selected = true;
        });
      }
      else element.querySelector(`option[value="${value}"]`).selected = true;
    }

  }
}